import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { DataService } from './data.service'; // Import the data service

import { DashboardData } from './data.service';
import { SyncPayload } from './models/sync.model';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  private baseUrl = 'https://api.poursoulsystems.com';

  constructor(private http: HttpClient, private authService: AuthService) {}
	
	//try and sync kegs and taps with API
	sendSyncRequest(payload: SyncPayload): Observable<any> {
    return this.http.post(`${this.baseUrl}/tap/sync`, payload).pipe(
      catchError((error) => {
        console.error('Error in sync request:', error);
        return throwError(error);
      })
    );
  }
	
	updateFcmToken(token: any): Observable<any> {
		return this.http.put(`${this.baseUrl}/user/updateFcmToken`, token).pipe(
			catchError((error) => {
				console.error('Error updating FCM token:', error);
				return throwError(error);
			})
		);
	}
  
  fetchDashboardData(): Observable<any> {
		return this.http.get(`${this.baseUrl}/user/dashboard`).pipe(
			catchError((error) => {
				console.error('Error fetching dashboard data:', error);
				return throwError(error);
			})
		);
  }
	
	fetchKegLevels(): Observable<any> {
		return this.http.get(`${this.baseUrl}/keg/levelsv2`).pipe(
			catchError((error) => {
				console.error('Error fetching keg levels:', error);
				return throwError(error);
			})
		);
	}

  // Get user details
  getUser(): Observable<any> {
		return this.http.get(`${this.baseUrl}/user`).pipe(
			catchError((error) => {
				console.error('Error getting user:', error);
				return throwError(error);
			})
		);
  }
	
	// Get all pour data from completed/tapped kegs
  getAllPours(): Observable<any> {
		return this.http.get(`${this.baseUrl}/keg/allPours`).pipe(
			catchError((error) => {
				console.error('Error getting all pour data:', error);
				return throwError(error);
			})
		);
  }
	
	  // Register a new user
  createUser(userData: any): Observable<any> {
		return this.http.post(`${this.baseUrl}/user`, userData).pipe(
			catchError((error) => {
				console.error('Error creating new user:', error);
				return throwError(error);
			})
		);
  }
	
	createTap(tapData: any): Observable<any> {
		return this.http.post(`${this.baseUrl}/tap`, tapData).pipe(
			catchError((error) => {
				console.error('Error creating new tap:', error);
				return throwError(error);
			})
		);
	}
  
  // Keg routes
  createKeg(kegData: any): Observable<any> {
		return this.http.post(`${this.baseUrl}/keg`, kegData).pipe(
			catchError((error) => {
				console.error('Error creating keg:', error);
				return throwError(error);
			})
		);
  }
	
	createKegs(kegsData: any[]): Observable<any[]> {
    return this.http.post<any[]>(
      `${this.baseUrl}/keg/batch`, 
      kegsData
    ).pipe(
      catchError((error) => {
        console.error('Error creating kegs:', error);
        return throwError(error);
      })
    );
  }
	
	createBeer(beerData: any): Observable<any> {
		return this.http.post(`${this.baseUrl}/beer`, beerData).pipe(
			catchError((error) => {
				console.error('Error creating beer:', error);
				return throwError(error);
			})
		);
	}
	
	createBeers(beersData: any[]): Observable<any[]> {
    return this.http.post<any[]>(
      `${this.baseUrl}/beer/batch`, 
      beersData
    ).pipe(
      catchError((error) => {
        console.error('Error creating beers:', error);
        return throwError(error);
      })
    );
  }
	
	createBrewery(breweryData: any): Observable<any> {
		return this.http.post(`${this.baseUrl}/brewery`, breweryData).pipe(
			catchError((error) => {
				console.error('Error creating brewery:', error);
				return throwError(error);
			})
		);
	}
	
	createBreweries(breweriesData: any[]): Observable<any[]> {
    return this.http.post<any[]>(
      `${this.baseUrl}/brewery/batch`, 
      breweriesData
    ).pipe(
      catchError((error) => {
        console.error('Error creating breweries:', error);
        return throwError(error);
      })
    );
  }
	
	nextKeg(tapId: string, swapTime?: number): Observable<any> {
		const body = swapTime ? { swapTime } : {};
		return this.http.post(`${this.baseUrl}/tap/${tapId}/nextKeg`, body).pipe(
			catchError((error) => {
				console.error('Error setting next keg:', error);
				return throwError(error);
			})
		);
	}
	
	// update kegQueue with user selections
	updateTapKegQueue(tapId: string, kegQueue: string[]): Observable<any> {
		return this.http.put(`${this.baseUrl}/tap/${tapId}/kegQueue`, { kegQueue }).pipe(
			catchError((error) => {
				console.error('Error updating tap keg queue:', error);
				return throwError(error);
			})
		);
	}

	// update keg by id
	updateKegById(kegId: string, updateData: any): Observable<any> {
		return this.http.put(`${this.baseUrl}/keg/${kegId}`, updateData).pipe(
			catchError((error) => {
				console.error('Error updating keg:', error);
				return throwError(error);
			})
		);
	}
	
	// update user
	updateUser(updateData: any): Observable<any> {
		console.log("backend service now..........updating user.........");
		return this.http.put(`${this.baseUrl}/user`, updateData).pipe(
			catchError((error) => {
				console.error('Error updating user:', error);
				return throwError(error);
			})
		);
	}

  deleteKeg(id: string): Observable<any> {

    return this.http.delete(`${this.baseUrl}/keg/${id}`).pipe(
			catchError((error) => {
				console.error('Error deleting keg:', error);
				return throwError(error);
			})
		);
  }


  getAllKegs(): Observable<any> {

    return this.http.get(`${this.baseUrl}/keg`);
  }

  getKegById(id: string): Observable<any> {

    return this.http.get(`${this.baseUrl}/keg/id/${id}`);
  }
	
	// Cleaning Event routes
  createCleaningEvent(eventData: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/cleaning`, eventData).pipe(
      catchError((error) => {
        console.error('Error creating cleaning event:', error);
        return throwError(error);
      })
    );
  }

  deleteCleaningEvent(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/cleaning/${id}`).pipe(
      catchError((error) => {
        console.error('Error deleting cleaning event:', error);
        return throwError(error);
      })
    );
  }

  updateCleaningEventById(eventId: string, updateData: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/cleaning/${eventId}`, updateData).pipe(
      catchError((error) => {
        console.error('Error updating cleaning event:', error);
        return throwError(error);
      })
    );
  }

  getAllCleaningEvents(): Observable<any> {
    return this.http.get(`${this.baseUrl}/cleaning`).pipe(
      catchError((error) => {
        console.error('Error fetching cleaning events:', error);
        return throwError(error);
      })
    );
  }
	
	returnAllKegsToInventory(tapId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/tap/${tapId}/returnAllKegsToInventory`, {}).pipe(
      catchError((error) => {
        console.error('Error returning all kegs to inventory with pours:', error);
        return throwError(error);
      })
    );
	}
	
	//backend service functions for CRUD pricing profiles
	createPricingProfile(profileData: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/pricing`, profileData).pipe(
      catchError((error) => {
        console.error('Error creating pricing profile:', error);
        return throwError(error);
      })
    );
  }

  updatePricingProfile(profileId: string, profileData: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/pricing/${profileId}`, profileData).pipe(
      catchError((error) => {
        console.error('Error updating pricing profile:', error);
        return throwError(error);
      })
    );
  }

  deletePricingProfile(profileId: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/pricing/${profileId}`).pipe(
      catchError((error) => {
        console.error('Error deleting pricing profile:', error);
        return throwError(error);
      })
    );
  }
	
	// New method for image analysis
  analyzeImage(formData: FormData): Observable<any> {
    return this.http.post(`${this.baseUrl}/keg/analyze-image`, formData).pipe(
      catchError((error) => {
        console.error('Error analyzing image:', error);
        return throwError(error);
      })
    );
  }
  
}


